import type {
  ComponentRef,
  ComponentClientSpecMapEntry,
} from '@wix/editor-platform-sdk-types';
import type {
  EditorScriptFlowAPI,
  FlowEditorSDK,
} from '@wix/yoshi-flow-editor';
import { PLATFORM_TOKEN } from '../constants';

export abstract class EditorPlatformMigration {
  public abstract name: string;

  public install(
    component: ComponentClientSpecMapEntry,
    ref: ComponentRef,
  ): Promise<boolean> | boolean {
    return true;
  }

  public migrate(
    component: ComponentClientSpecMapEntry,
    ref: ComponentRef,
  ): Promise<boolean> | boolean {
    return true;
  }

  constructor(
    protected readonly sdk: FlowEditorSDK,
    protected readonly flowAPI: EditorScriptFlowAPI,
  ) {}

  public async setMigrationStatus(done: boolean, compRef: ComponentRef) {
    await this.sdk.document.tpa.data.set(PLATFORM_TOKEN, {
      compRef,
      value: done,
      scope: 'COMPONENT',
      key: this.migrationKey,
    });
  }

  public async isMigrationNeeded(compRef: ComponentRef): Promise<boolean> {
    const data = await this.sdk.document.tpa.data.getAll(PLATFORM_TOKEN, {
      compRef,
    });

    return data.COMPONENT?.[this.migrationKey] !== true;
  }

  public get migrationKey() {
    return `migration-${this.name}-done`;
  }
}
