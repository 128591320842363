import {
  ComponentRef,
  ComponentClientSpecMapEntry,
} from '@wix/editor-platform-sdk-types';

import { migrate } from 'settings/migration';

import { PLATFORM_TOKEN } from '../../constants';
import { EditorPlatformMigration } from '../EditorPlatformMigration';
import {
  toColorStyleParams,
  toFontStyleParams,
  toNumericStyleParams,
} from './helpers';

/**
 * Migrates legacy settings
 */
export default class extends EditorPlatformMigration {
  name = 'styleParams-01';

  async migrate(component: ComponentClientSpecMapEntry, ref: ComponentRef) {
    const [styleParams, publicData] = await Promise.all([
      this.sdk.document.tpa.getStyleParams(PLATFORM_TOKEN, {
        compRef: ref,
      }),
      this.sdk.document.tpa.data.getAll(PLATFORM_TOKEN, { compRef: ref }),
    ]);

    const result = migrate(styleParams, publicData);

    const fonts = toFontStyleParams(result.fonts);
    const colors = toColorStyleParams(result.colors);
    const numbers = toNumericStyleParams(result.numbers);

    await this.sdk.document.tpa.setStyleParams(PLATFORM_TOKEN, {
      compRef: ref,
      styleParams: [fonts, colors, numbers].flat(),
    });

    return true;
  }
}
